.sponsor {
    margin-top : 30px;
}
.sponsor-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sponsor-icon .cifo img {
    width: 100px;
    margin-right: 30px;
}
.sponsor-icon .walanja img {
    width: 60px;
    margin-right: 30px;
}
.sponsor-icon .citra img {
    width: 50px;
    margin-right: 30px;
}
.sponsor-icon .simaya img {
    width: 100px;
}
.catatan {
    margin-top: 40px;
    border-left: 5px #4997db solid;
    padding-left: 20px;
}
.perincian-invoice table tbody tr td.blue {
    background-color : #4997db;
    color: #fff;
}
.perincian-invoice table thead tr th,
.perincian-invoice table tbody tr td,
.perincian-invoice table tfoot tr td{
    background-color: #cecece;
    padding: 15px;
}
.perincian-invoice table {
    border-collapse:separate; 
    border-spacing: 0 .1em;
    width: 100%;
}   
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}        
.periode-invoice {
    margin-top: 30px;
}
.noinvoice {
    color : #0f63e0;
    font-weight: bold;
    font-size: 18px;
}
.noinvoice, .periode-invoice {
    text-align: right;
}
.alamat-pelanggan {
    margin-top: 20px;
}
.invoice-container {
    background-color: #fff;
    min-height: 29.7cm;
    padding-left: 1cm;
    padding-right: 1cm;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 16px;
    padding-top: 1.5cm;
}


.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #0f63e0;
}
.header .logo {
    flex : 1;
}
.header .logo img {
    width: 150px;
}
.alamat-header {
    flex: 1;
    text-align: right;
}
.alamat-header .perusahaan-name {
    font-weight: bold;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.bold {
    font-weight: bold;
}

@media print { 
    body {
        background-color: #fff;
    }

}