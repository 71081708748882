/* body {
    background-color: #D5E0E3;
} */

.iklan-container {    
    width: 21cm !important;        
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 auto;
    height: 29.3cm;
    /* background-color: #fff; */
}

.table-ads-satu tbody tr td {
    margin-left: 2px;
}

.step-satu-container.view-mobile{
    position: relative;
}

.mobile-rotate-icon-container {
    position: fixed;
    top: 0%;
    right: 0%;
    /* bottom: 10px;
    width: 180px;
    overflow-y: scroll; */
}

.mobile-rotate-icon-container  img{
    width: 60px;
    opacity: .5;
}

.step-satu-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;    
}

.view-mobile {
    transform: rotate(90deg);
    margin-top: 80px;    
}

.box-ul-ads-fitur {
    width: 300px;
}

.box-ads-shadow {
    box-shadow: 5px 6px 13px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 6px 13px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 6px 13px -1px rgba(0,0,0,0.75);
    
}

.box-ads-title {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
}

.box-ads-title-blue {
    background-color: #287089;
    color : #FFF;
}

.box-ads-oval {
    position: relative;
    background-color: #A7B6B5;
    width: 100%;
    height: 7px;
    border-radius: 40% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
    -moz-transform:    scaleX(-1); /* Gecko */
    -o-transform:      scaleX(-1); /* Opera */
    -webkit-transform: scaleX(-1); /* Webkit */
    transform: rotate(180deg); /* Standard */
    filter: FlipH;                 /* IE 6/7/8 */
}

.ul-ads-description {
    list-style: none;
    padding: 0 !important
}

.ul-ads-description li {
    background-color: #CBE0E5;    
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #386373;
    display: flex;    
    align-items: flex-start;
}

.ul-ads-description li .bullet {    
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-right: 20px;    
    background-color: #386373;
    margin-top: 5px;
}

.ul-ads-description li .li-content {
    width: 80%;
}

.ul-ads-description li .li-title {
    font-weight: bold;
}
.ul-ads-description li:nth-of-type(odd) {
    background-color: #fff ;
}

.box-ul-ads-higo {
    margin-left: 20px;
    width: 200px;
}
.box-ul-ads-higo ul li {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-ul-ads-higo ul li .li-content{
    font-size: 25px;
    text-align: center;
}
.box-ul-ads-higo ul li .li-content.space-more{
    height: 100px;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
}
.box-ul-ads-higo ul li .li-content.space-more-1{
    height: 55px;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
}